<template>
  <v-container
    class="pa-2"
    style="overflow-y: auto; height: 100%; width: 100%"
    fluid
  >
    <v-btn
      left
      absolute
      elevation="0"
      icon
      color="neutral"
      @click="goBack"
    >
      <v-icon>{{ icons.goBack }}</v-icon>
    </v-btn>

    <tour
      ref="tour"
      name="panel-creator-tour"
      :steps="tourSteps"
    />

    <v-container
      fluid
      class="pt-0"
      style="max-width: 800px"
    >
      <v-form
        ref="form"
        v-model="valid"
        :class="$vuetify.breakpoint.mobile ? 'mt-8': 'mt-0'"
      >
        <v-row
          class="align-center"
          no-gutters
        >
          <div
            class="subtitle-1 font-weight-bold neutralPrimary--text"
          >
            {{ $t('deconve.name') }}
          </div>
          <v-spacer />
          <v-btn
            icon
            elevation="0"
            :small="$vuetify.breakpoint.mobile"
            @click="startTour"
          >
            <v-icon color="neutral">
              $tipsAndUpdatesIcon
            </v-icon>
          </v-btn>
        </v-row>

        <v-text-field
          id="unit-register-name"
          v-model="name"
          data-dd-privacy="mask-user-input"
          max-width="100%"
          counter="64"
          :rules="nameRules"
          outlined
          required
          dense
        />

        <div
          class="px-0"
        >
          <span
            id="unit-register-coordinates"
            class="subtitle-1 font-weight-bold neutralPrimary--text"
          >
            {{ $t('deconve.coordinates') }}
          </span>
          <v-row class="mx-0 my-1">
            <v-col
              :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : 'pa-0 pr-2'"
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                v-model="latitude"
                type="number"
                data-dd-privacy="mask-user-input"
                :rules="latitudeRules"
                :label="$t('deconve.latitude')"
                min="-90"
                max="90"
                max-width="100%"
                dense
                outlined
                required
              />
            </v-col>

            <v-col
              :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : 'pa-0 pr-2'"
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                v-model="longitude"
                type="number"
                data-dd-privacy="mask-user-input"
                :rules="longitudeRules"
                :label="$t('deconve.longitude')"
                min="-180"
                max="180"
                max-width="100%"
                dense
                outlined
                required
              />
            </v-col>
          </v-row>
        </div>

        <div
          v-if="isToShowVehicleSettings"
          class="px-0"
        >
          <span
            id="unit-register-people-by-vehicles"
            class="subtitle-1 font-weight-bold neutralPrimary--text"
          >
            {{ $t('deconve.doohDashboard.peopleByVehicles') }}
          </span>
          <v-row class="mx-0 my-1">
            <v-col
              :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : 'pa-0 pr-2'"
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                v-model="car"
                type="number"
                data-dd-privacy="mask-user-input"
                :rules="vehicleRules"
                :label="$t('deconve.car')"
                step="0.1"
                min="1"
                max-width="100%"
                dense
                outlined
                required
              />
            </v-col>
            <v-col
              :class="$vuetify.breakpoint.xsOnly ? 'pa-0 ' : 'pa-0 pr-2'"
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                v-model="motorbike"
                type="number"
                data-dd-privacy="mask-user-input"
                :rules="vehicleRules"
                :label="$t('deconve.motorbike')"
                step="0.1"
                min="1"
                max-width="100%"
                dense
                outlined
                required
              />
            </v-col>
            <v-col
              :class="$vuetify.breakpoint.xsOnly ? 'pa-0 ' : 'pa-0 pr-2'"
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                v-model="bus"
                type="number"
                data-dd-privacy="mask-user-input"
                :rules="vehicleRules"
                :label="$t('deconve.bus')"
                step="0.1"
                min="1"
                max-width="100%"
                dense
                outlined
                required
              />
            </v-col>
            <v-col
              class="pa-0"
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                v-model="truck"
                type="number"
                data-dd-privacy="mask-user-input"
                :rules="vehicleRules"
                :label="$t('deconve.truck')"
                step="0.1"
                min="1"
                max-width="100%"
                dense
                outlined
                required
              />
            </v-col>
          </v-row>
        </div>

        <span
          id="unit-register-tags"
          class="subtitle-1 font-weight-bold neutralPrimary--text"
        >
          {{ $t('deconve.tags') }}
        </span>
        <v-row
          class="ma-0 pt-1 pb-5 align-center"
        >
          <v-btn
            v-if="tags.length === 0"
            outlined
            color="neutral"
            @click="openTagModal"
          >
            <v-icon left>
              {{ icons.addTag }}
            </v-icon>

            <span>
              {{ $t('deconve.tag.addTags') }}
            </span>
          </v-btn>

          <div
            v-else
            class="d-flex flex-wrap align-center"
          >
            <tag
              v-for="(tag, index) in tags"
              :key="tag.id"
              show-delete-label
              class="pa-1"
              :tag-id="tag.id"
              @delete="deleteTag(index)"
            />
            <v-btn
              outlined
              color="neutral"
              fab
              x-small
              @click="openTagModal"
            >
              <v-icon>
                {{ icons.addTag }}
              </v-icon>
            </v-btn>
          </div>
        </v-row>

        <span
          id="unit-register-videos"
          class="subtitle-1 font-weight-bold neutralPrimary--text"
        >
          {{ $t('deconve.videos') }}
        </span>
        <v-autocomplete
          v-model="selectedVideoIds"
          :items="videos"
          data-dd-privacy="mask-user-input"
          chips
          dense
          item-text="name"
          item-value="id"
          multiple
          outlined
          clearable
          hide-details
        />

        <v-switch
          id="unit-register-enable-image-preview"
          v-model="enableImagePreview"
          :label="$t('deconve.enableImagePreview')"
        />

        <v-switch
          id="unit-register-enable-auto-update"
          v-model="enableAutoUpdate"
          :label="$t('deconve.enableAutoUpdate')"
        />

        <v-row
          no-gutters
          class="mt-4"
        >
          <v-spacer />
          <v-btn
            outlined
            depressed
            class="mr-2"
            color="neutral"
            @click="cancel"
          >
            {{ $t('deconve.cancel') }}
          </v-btn>

          <v-btn
            filled
            depressed
            color="primary"
            :disabled="!valid"
            :loading="isLoading"
            @click="onSaveButtonClicked"
          >
            {{ isEditMode ? $t('deconve.submit') : $t('deconve.save') }}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog
      v-model="showApiKeyDialog"
      persistent
      max-width="360"
    >
      <v-card>
        <v-card-title>
          {{ isEditMode ? $t('deconve.unitEdited') : $t('deconve.unitAdded') }}
        </v-card-title>
        <v-card-text>
          {{ $t('deconve.unitCreatedMessage') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            outlined
            depressed
            color="neutral"
            @click="onDoNotCreateApiKey"
          >
            {{ $t('deconve.no') }}
          </v-btn>
          <v-btn
            filled
            depressed
            color="success"
            @click="onCreateApiKey"
          >
            {{ $t('deconve.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <tags-manager-dialog ref="tagsDialog" />
    <api-keys-manager-dialog ref="apiKeysDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { mdiChevronLeft, mdiTagPlusOutline } from '@mdi/js';

import ApiKeysManagerDialog from '@/components/ApiKeysManagerDialog.vue';
import Tour from '@/components/Tour.vue';
import Tag from '@/components/Tag.vue';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';

export default {
  name: 'UnitRegister',
  components: {
    ApiKeysManagerDialog,
    Tag,
    TagsManagerDialog,
    Tour,
  },
  props: {
    unitId: { type: String, default: '' },
  },
  data() {
    return {
      uid: undefined,
      isEditMode: false,
      tags: [],
      showApiKeyDialog: false,
      name: undefined,
      car: '1',
      motorbike: '1',
      bus: '1',
      truck: '1',
      latitude: null,
      longitude: null,
      panels: [],
      selectedVideoIds: [],
      enableImagePreview: true,
      enableAutoUpdate: true,
      valid: false,
      resolve: null,
      isLoading: false,
      icons: {
        addTag: mdiTagPlusOutline,
        goBack: mdiChevronLeft,
      },
    };
  },
  computed: {
    ...mapGetters({
      videos: 'videos/videos',
      getUnit: 'units/getUnit',
    }),
    isToShowVehicleSettings() {
      return Vue.prototype.$ability.can('use', 'com.deconve.analytics.dooh')
        || Vue.prototype.$ability.can('use', 'com.deconve.analytics.vehicleflow');
    },
    latitudeRules() {
      return [
        (v) => !!v || this.$t('deconve.invalidValue'),
        (v) => (Math.abs(parseFloat(v)) <= 90) || this.$t('deconve.invalidValue'),
      ];
    },
    longitudeRules() {
      return [
        (v) => !!v || this.$t('deconve.invalidValue'),
        (v) => (Math.abs(parseFloat(v)) <= 180) || this.$t('deconve.invalidValue'),
      ];
    },
    nameRules() {
      return [
        (v) => !!v || this.$t('deconve.inputRules.name.required'),
        (v) => (v && v.length <= 64) || this.$t('deconve.inputRules.name.limit'),
      ];
    },
    vehicleRules() {
      return [
        (v) => !!v || this.$t('deconve.invalidValue'),
        (v) => parseFloat(v) >= 0 || this.$t('deconve.invalidValue'),
      ];
    },
    tourSteps() {
      const steps = [
        {
          target: '#unit-register-name',
          content: this.$t('deconve.onboarding.unitRegister.name'),
        },
        {
          target: '#unit-register-coordinates',
          content: this.$t('deconve.onboarding.unitRegister.coordinates'),
        }];

      if (this.isToShowVehicleSettings) {
        steps.push({
          target: '#unit-register-people-by-vehicles',
          content: this.$t('deconve.onboarding.unitRegister.peopleByVehicles'),
          params: {
            placement: 'top',
          },
        });
      }

      steps.push({
        target: '#unit-register-tags',
        content: this.$t('deconve.onboarding.unitRegister.tags'),
        params: {
          placement: 'top',
        },
      });

      steps.push({
        target: '#unit-register-videos',
        content: this.$t('deconve.onboarding.unitRegister.videos'),
        params: {
          placement: 'top',
        },
      });

      steps.push({
        target: '#unit-register-enable-image-preview',
        content: this.$t('deconve.onboarding.unitRegister.enableImagePreview'),
        params: {
          placement: 'top',
        },
      });

      steps.push({
        target: '#unit-register-enable-auto-update',
        content: this.$t('deconve.onboarding.unitRegister.enableAutoUpdate'),
        params: {
          placement: 'top',
        },
      });

      return steps;
    },
  },
  created() {
    this.uid = this.unitId;

    if (this.uid) {
      this.isEditMode = true;
      this.isLoading = true;

      this.fetchUnit(this.uid).then((data) => {
        this.initEdit(data);
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    } else {
      this.isEditMode = false;
    }

    this.isLoading = true;

    this.fetchVideos().then(() => {
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      addUnit: 'units/addUnit',
      editUnit: 'units/editUnit',
      fetchUnit: 'units/fetchUnit',
      fetchVideos: 'videos/fetchVideos',
    }),
    startTour() {
      this.$refs.tour.start();
    },
    goBack() {
      this.$emit('onGoBackButtonClicked');
    },
    openTagModal() {
      this.$refs.tagsDialog.open(this.tags, true).then((tags) => {
        if (tags) {
          this.tags = tags.map((tag) => ({ id: tag.id }));
        }
      });
    },
    deleteTag(index) {
      this.tags.splice(index, 1);
    },
    initEdit(unitData) {
      const {
        name,
        people_flow_settings: peopleFlowSettings,
        tags,
        videos,
        enable_image_preview: enableImagePreview,
        is_auto_update_enabled: enableAutoUpdate,
        gps_latitude: latitude,
        gps_longitude: longitude,
      } = unitData;

      this.tags = tags;
      this.name = name || '';
      this.car = peopleFlowSettings?.car || '';
      this.motorbike = peopleFlowSettings?.motorbike || '';
      this.bus = peopleFlowSettings?.bus || '';
      this.truck = peopleFlowSettings?.truck || '';
      this.enableImagePreview = enableImagePreview;
      this.enableAutoUpdate = enableAutoUpdate || false;
      this.latitude = latitude || null;
      this.longitude = longitude || null;

      this.selectedVideoIds = videos.map((video) => {
        const v = this.videos.find(({ id }) => id === video.id);

        return v?.id;
      });
    },
    submit() {
      const tagIds = this.tags.map(({ id }) => id);

      const data = {
        tagIds,
        name: this.name,
        videoIds: this.selectedVideoIds,
        peopleFlowSettings: {
          car: Number(this.car),
          bus: Number(this.bus),
          motorbike: Number(this.motorbike),
          truck: Number(this.truck),
        },
        enableImagePreview: this.enableImagePreview,
        enableAutoUpdate: this.enableAutoUpdate,
        latitude: Number(this.latitude),
        longitude: Number(this.longitude),
      };

      this.addUnit(data).then((uid) => {
        this.uid = uid;
        this.showApiKeyDialog = true;
      }).catch();
    },
    submitUpdate() {
      const tagIds = this.tags.map(({ id }) => id);

      const data = {
        tagIds,
        unitId: this.uid,
        name: this.name,
        videoIds: this.selectedVideoIds,
        peopleFlowSettings: {
          car: Number(this.car),
          bus: Number(this.bus),
          motorbike: Number(this.motorbike),
          truck: Number(this.truck),
        },
        enableImagePreview: this.enableImagePreview,
        enableAutoUpdate: this.enableAutoUpdate,
        latitude: Number(this.latitude),
        longitude: Number(this.longitude),
      };

      this.editUnit(data).then(() => {
        this.reset();
        this.$emit('edited');
      }).catch();
    },
    onSaveButtonClicked() {
      if (this.isEditMode) {
        this.submitUpdate();
      } else {
        this.submit();
      }
    },
    onDoNotCreateApiKey() {
      this.reset();

      if (this.isEditMode) {
        this.$emit('edited');
      } else {
        this.$emit('registered', this.uid);
      }
    },
    addApiKey() {
      return new Promise((resolve) => {
        const tags = this.tags.map(({ id }) => ({ tag: { id } }));
        const apiKey = {
          tags,
          id: undefined,
          name: this.name,

          // eslint-disable-next-line @typescript-eslint/camelcase
          unit_id: this.uid,
        };

        this.$refs.apiKeysDialog.open(apiKey).then(() => {
          resolve();
        });
      });
    },
    onCreateApiKey() {
      this.addApiKey().then(() => {
        if (this.isEditMode) {
          this.$emit('edited');
        } else {
          this.$emit('registered', this.uid);
        }
      });
    },
    reset() {
      this.showApiKeyDialog = false;
      this.$refs.form.reset();

      this.name = undefined;
      this.bus = '1';
      this.car = '1';
      this.motorbike = '1';
      this.truck = '1';
      this.tags = [];
      this.selectedVideoIds = [];
      this.enableImagePreview = true;
      this.enableAutoUpdate = true;
      this.latitude = null;
      this.longitude = null;
    },
    cancel() {
      this.reset();
      this.goBack();
    },
  },
};
</script>
